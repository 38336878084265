import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  NavigationAPIOptionalParams,
  GetNavigationOptionalParams,
  GetNavigationResponse,
} from "./models";

export class NavigationAPI extends coreClient.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the NavigationAPI class.
   * @param $host server parameter
   * @param options The parameter options
   */
  constructor($host: string, options?: NavigationAPIOptionalParams) {
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: NavigationAPIOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
    };

    const packageDetails = `azsdk-js-navigation-rest-client/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}",
    };
    super(optionsWithDefaults);
    // Parameter assignments
    this.$host = $host;
  }

  /**
   * By passing in the appropriate options, you can search for
   * available inventory in the system
   *
   * @param currentNodeId the Id for the current node
   * @param options The options parameters.
   */
  getNavigation(
    currentNodeId: number,
    options?: GetNavigationOptionalParams,
  ): Promise<GetNavigationResponse> {
    return this.sendOperationRequest(
      { currentNodeId, options },
      getNavigationOperationSpec,
    );
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const getNavigationOperationSpec: coreClient.OperationSpec = {
  path: "/navigation/{currentNodeId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } },
      },
    },
    400: {},
  },
  urlParameters: [Parameters.$host, Parameters.currentNodeId],
  headerParameters: [Parameters.accept],
  serializer,
};
