
import Vue from "vue";
import { MenuLevel } from "../Models/MenuLevel";
import { MenuLevel as MenuLevelApi } from "../client/src/models"
import {
  NavigationAPI,
} from "../client/src/navigationAPI";
import smoothReflow from "vue-smooth-reflow";


export default Vue.extend({
  name: "MobileMenu",
  props: {
    currentpage: Number,
    root: Number,
    ocpApimSubscriptionKey: String,
    apidomain: String,
  },
  data() {
    return {
      menuLinks: [] as MenuLevelApi[],
      utilityLinks: [] as MenuLevelApi[],
      currentPosition: [],
      levels: [] as MenuLevelApi[],
      currentLevel: {} as MenuLevelApi,
      prevLevelDepth: 0 as number,
      currentLevelDepth: 0 as number,
    };
  },
  computed: {
    transitionName(): string {
      return (
        "slide-" +
        (this.currentLevelDepth > this.prevLevelDepth ? "left" : "right")
      );
    },
  },
  methods: {
    navigate(path: number[]) {
      this.$data.currentPosition = path;
      this.recalculateItems();
      this.$nextTick(() => {
        (this.$refs[
          `level-${this.getPathName(this.$data.currentPosition)}`
        ] as HTMLBaseElement).focus();
      });
    },

    recalculateItems() {
      let items = this.$data.menuLinks;
      const levels: MenuLevelApi[] = [];

      levels.push(this.getLevel([], "", items));
      this.currentPosition.forEach((index, depth) => {
        const path = [...this.currentPosition].slice(0, depth + 1);
        const label = (items[index] && items[index].label) || null;
        items = (items[index] && items[index].items) || [];
        levels.push(this.getLevel(path, label, items));
      });

      this.$data.levels = levels;
      this.currentLevel = levels[levels.length - 1];
      this.prevLevelDepth = this.currentLevelDepth;
      this.currentLevelDepth = levels.length - 1;
    },

    getLevel(
      path: number[],
      label: string,
      items: MenuLevelApi[]
    ): MenuLevelApi {
      return {
        path: path,
        label: label,
        hasItems: items.length > 0,
        isActive: false,
        url: "",
        items: items.map((item, index) => {
          return {
            path: [...path, index],
            label: item.label,
            url: item.url,
            isActive: item.isActive,
            hasItems: (item.items && item.items.length > 0) || false,
          };
        }),
      };
    },

    getPathName(path: string[]) {
      if (!path || path.length < 0) {
        return 0;
      }

      return [0, ...path].join("-");
    },

    handleArrowNavigation(direction: number) {
      if (!this.$refs.link) {
        return;
      }

      const links = [];
      if (this.$refs.back) {
        links.push(this.$refs.back);
      }
      links.push(...(this.$refs.link as HTMLBaseElement[]));
      if (links.includes(document.activeElement as HTMLBaseElement)) {
        let nextIndex =
          links.indexOf(document.activeElement as HTMLBaseElement) + direction;
        if (nextIndex < 0) {
          nextIndex = links.length - 1;
        } else if (nextIndex > links.length - 1) {
          nextIndex = 0;
        }
        (links as HTMLBaseElement[])[nextIndex].focus();
      } else {
        if (direction > 0) {
          (this.$refs.link as HTMLBaseElement[])[0].focus();
        } else {
          (links as HTMLBaseElement[])[links.length - 1].focus();
        }
      }
    },
    async getNavigationdata() {
      const client = new NavigationAPI(`${window.location.origin}/${this.apidomain}`);
      const apimKey = this.ocpApimSubscriptionKey!.toString();

      const result = await client.getNavigation(this.root, {
        requestOptions: {
          customHeaders: {
            "ocp-apim-subscription-key": apimKey,
            "Accept": "application/json;v=1"
          }
        }
      });
      this.$data.menuLinks = result.menuItems as MenuLevelApi[];
      this.$data.utilityLinks = result.utilityMenuItems as MenuLevelApi[];
      this.recalculateItems();
      const currentPath = (result.pathLookup as any)[this.currentpage] || [];
      this.navigate(currentPath as number[]);
    },
  },

  mounted() {
    this.getNavigationdata();
    let typedef: Vue["$refs"];
    this.$smoothReflow &&
      this.$smoothReflow({
        el: (this.$refs.levels as Vue).$el,
      });
  },


});
