
import Vue from "vue";
import MobileMenu from "./components/MobileMenu.vue";

export default Vue.extend({
  name: "App",
  props: {
    currentpageid: {
      type: Number,
      required: true,
      default: 0.1
    },
    root: {
      type: Number,
      required: true,
      default: 0.1
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: ""
    },
    apidomain: {
      type: String,
      required: false,
      default: ""
    },
  },
  components: {
    MobileMenu
  },
});
