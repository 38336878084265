import { OperationParameter, OperationURLParameter } from "@azure/core-client";

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const currentNodeId: OperationURLParameter = {
  parameterPath: "currentNodeId",
  mapper: {
    constraints: {
      InclusiveMinimum: 0,
    },
    serializedName: "currentNodeId",
    required: true,
    type: {
      name: "Number",
    },
  },
};
