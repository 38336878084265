import * as coreClient from "@azure/core-client";

export const MenuResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MenuResponse",
    modelProperties: {
      menuItems: {
        serializedName: "MenuItems",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MenuLevel",
            },
          },
        },
      },
      pathLookup: {
        serializedName: "PathLookup",
        required: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "Composite", className: "Lookup" } },
        },
      },
    },
  },
};

export const MenuLevel: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MenuLevel",
    modelProperties: {
      path: {
        serializedName: "Path",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number",
            },
          },
        },
      },
      hasItems: {
        serializedName: "HasItems",
        type: {
          name: "Boolean",
        },
      },
      label: {
        serializedName: "Label",
        required: true,
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "Url",
        required: true,
        type: {
          name: "String",
        },
      },
      items: {
        serializedName: "Items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MenuLevel",
            },
          },
        },
      },
      isActive: {
        serializedName: "IsActive",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const Lookup: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Lookup",
    modelProperties: {
      code: {
        serializedName: "code",
        type: {
          name: "Number",
        },
      },
      text: {
        serializedName: "text",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number",
            },
          },
        },
      },
    },
  },
};
