import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = true;
const navEl = document.getElementById("sm-mobile-menu");
const pageid = Number(navEl?.getAttribute("data-currentpageid"));
const rootid = Number(navEl?.getAttribute("data-root"));
const key = navEl?.getAttribute("data-ocpApimSubscriptionKey");
const domain = navEl?.getAttribute("data-apidomain");
new Vue({
  render: (h) =>
    h(App, {
      props: {
        currentpageid: pageid,
        ocpApimSubscriptionKey: key,
        apidomain: domain,
        root: rootid,
      },
    }),
}).$mount("#sm-mobile-menu");
